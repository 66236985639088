:root {
  --primary-color: #229864;
  --sec-color: #1587a0;
  --sec2-color: #221f1c;
  --font-green: #229864;
  --font-blue: #053fe2;
  --font-org: #fe5f02;
  --bg-dark: #022a46;
}

html {
  scroll-behavior: smooth;
  height: 100%;
}
body {
  font-family: "Roboto", sans-serif;
  font-size: 400;
}
strong {
  font-weight: 700;
}
.btn-red {
  background-color: var(--primary-color);
}
.btn-blue {
  background-color: var(--sec-color);
}
.font-green {
  color: var(--primary-color);
}
.font-blue {
  color: var(--sec-color);
}
.font-org {
  color: var(--font-org);
}

.home {
  background-image: url("./assets/images/home-img.jpg");
  background-size: 100%;
  background-position: 100% 50%;
  min-height: 700px;
}
header {
  padding-top: 30px;
}
.logo {
  text-align: left;
}
.logo img {
  width: 135px;
}
.menus ul {
  padding: 0;
  margin: 0;
}
.menus ul li {
  padding: 0;
  list-style: none;
  font-size: 1.1rem;
  font-weight: 700;
  position: relative;
  margin: 0 10px;
}
.menus ul li:before {
  content: "/";
  position: absolute;
  width: 10px;
  top: 8px;
  left: -11px;
}
.menus ul li:first-child:before {
  content: "";
}
.menus ul li a {
  color: #221f1c;
}
.menus ul li a:hover,
.menus ul li a.active {
  color: var(--primary-color);
}

.top-rgt ul {
  padding: 0;
  margin: 0;
  text-align: right;
}
.top-rgt ul li {
  padding: 0;
  margin: 0 0 10px 0;
  list-style: none;
}
.signin-btn {
  width: 130px;
  padding: 10px 0;
  background-color: var(--primary-color);
}
.signin-btn span img {
  width: 13px;
  display: inline-block;
  margin-right: 10px;
}
.top-rgt ul li.app-icon img {
  width: 130px;
}
.home-mid {
  text-align: center;
}
.home-mid h1 {
  margin-top: 100px;
  font-weight: 700;
  font-size: 2.5rem;
}
.home-mid h1 span {
  font-weight: 400;
}
.home-mid p {
  margin: 20px 0 20px 0;
  font-size: 1.4rem;
}
.home-mid ul {
  padding: 0;
  margin: 0;
}
.home-mid ul li {
  display: inline-block;
  margin: 0 10px;
}
.home-btn {
  width: 305px;
  padding: 15px 0;
  font-size: 1.2rem;
  font-weight: 700;
}
.home-btn:hover {
  background-color: var(--sec2-color);
}
#popup-home h1 {
  font-size: 1.7rem;
  color: var(--sec-color);
  font-weight: 700;
}

.inner-wrap {
  min-height: 500px;
  padding: 50px 0 90px 0;
}
.inner-wrap h1 {
  font-weight: 700;
  font-size: 2.5rem;
  color: var(--primary-color);
}
.inn-btn {
  width: 100%;
  padding: 10px 0;
  font-size: 1.2rem;
}
.inn-btn2 {
  width: 270px;
  padding: 10px 0;
  font-size: 1.2rem;
}
.txt-field-1 {
  padding: 16px 20px;
  font-size: 1.1rem;
  font-weight: 300;
}
.otp-txt {
  text-align: right;
}
.otp-field-1 {
  padding: 16px;
  font-size: 1.1rem;
  font-weight: 300;
  text-align: center;
}
.s-info-txt {
  color: var(--primary-color);
  text-align: right;
}
.otp-txt2 {
  text-align: center;
}
.info-txt2 {
  margin-top: 15px;
}
.info-txt2 span {
  display: inline-block;
  margin-left: 30px;
  color: var(--font-blue);
}
.info-txt3 {
  color: #f38f0c;
  font-weight: 700;
  margin-bottom: 20px;
}
.info-txt4 p {
  margin: 0;
  color: var(--font-blue);
}
.btn-upload {
  background-color: var(--bg-dark);
  color: #fff;
  border-color: var(--bg-dark);
}
.browse-field.form-control::file-selector-button {
  padding: 16px 20px;
}
.top-rgt2 {
  text-align: right;
}
.top-rgt2 a {
  color: var(--primary-color);
  font-weight: 700;
  text-decoration: none;
}
.top-rgt2 .dropdown-menu a {
  color: #333;
}

.save-btn {
  text-align: right;
}
.save-btn a {
  color: #0041d9;
  text-decoration: none;
}
.save-btn a:hover {
  color: var(--primary-color);
  text-decoration: none;
}
.set-wrap h2 {
  font-weight: 700;
  font-size: 1.7rem;
}
.set-btn {
  text-align: left;
  padding: 10px;
}
.set-btn i {
  text-align: right;
  display: block;
  float: right;
}

.list-wrap {
  border: 1px solid #e6e1e1;
  padding: 13px;
  border-radius: 18px;
  margin: 30px 0 0 0;
}
.gal-wrap {
  display: flex;
}
.prot-lrg {
  width: 70%;
}
.prot-lrg img {
  border-radius: 12px;
}
.prot-list {
  width: 30%;
}
.prot-list ul {
  padding: 0;
  margin: 0 0 0 20px;
}
.prot-list ul li {
  padding: 0;
  margin: 0 0 10px 0;
  list-style: none;
}
.prot-list ul li img {
  border-radius: 12px;
  width: 81%;
}
.prot-list ul li:last-child {
  margin: 0;
}

.prop-details {
  margin: 0;
}
.prop-details h3 {
  font-weight: 700;
  font-size: 1.4rem;
  margin: 10px 0 20px 0;
}
.location {
  margin: 0 0 20px 0;
}
.location img {
  height: 23px;
  margin-right: 5px;
}
.prop-details ul {
  padding: 0;
  margin: 0;
}
.prop-details ul li {
  list-style: none;
  display: inline-flex;
  padding: 0 10px;
  border-left: 1px solid #231616;
}
.prop-details ul li:first-child {
  padding-left: 0;
  border: none;
}
.prop-details ul li img {
  height: 20px;
  margin-right: 6px;
}
.price {
  color: var(--primary-color);
  margin: 20px 0 0 0;
  font-size: 1.4rem;
  font-weight: 700;
}

.prot-img2 {
  width: 100%;
}
.prot-img2 img {
  border-radius: 7px;
}

.prop-details2 {
  margin: 0;
}
.brw-box {
  transition: 0.3s;
}
.brw-box:hover {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
.prop-details2 h3 {
  font-weight: 700;
  font-size: 1.2rem;
  margin: 15px 0;
}
.location2 {
  margin: 0 0 20px 0;
  font-size: 0.8rem;
}
.location2 img {
  height: 18px;
  margin-right: 5px;
}
.prop-details2 ul {
  padding: 0;
  margin: 0;
}
.prop-details2 ul li {
  list-style: none;
  display: inline-flex;
  padding: 0 7px;
  border-left: 1px solid #231616;
  font-size: 0.8rem;
}
.prop-details2 ul li:first-child {
  padding-left: 0;
  border: none;
}
.prop-details2 ul li img {
  height: 18px;
  margin-right: 6px;
}
.price2 {
  color: var(--primary-color);
  margin: 10px 0 0 0;
  font-size: 1.4rem;
  font-weight: 700;
}
.brw-field {
  font-size: 0.85rem;
}
.brw-field-out {
  position: relative;
}
.brw-icon {
  right: 23px;
  top: 5px;
  position: absolute;
}
.shot-by {
  text-align: right;
}
.brw-hd {
  font-weight: 700;
}
.browse-pagi li .page-item:first-child .page-link {
}
.browse-pagi li.active > .page-link,
.page-link.active {
  color: #fff;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
.browse-pagi .page-link {
  color: #000;
}

.listing-status {
  border-left: 1px solid #e6e1e1;
  height: 100%;
  padding: 30px 20px;
}
.listing-status h5 {
  font-weight: 700;
  font-size: 1rem;
  padding: 10px 0;
  margin: 10px 0 20px 0;
  background-color: #f3f0f0;
  border-radius: 50px;
  text-align: center;
}
.listing-status ul {
  padding: 0;
  margin: 0;
}
.listing-status ul li {
  list-style: none;
  padding: 10px 0;
  border-bottom: 1px solid #e6e1e1;
  margin: 5px 0;
}
.listing-status ul li:first-child {
  border-top: 1px solid #e6e1e1;
}
.st-logo {
  width: 100px;
}
.listing-status ul li span {
  display: inline-block;
  margin-left: 50px;
}
.listing-status ul li span img {
  width: 20px;
}
.map-sticky {
  position: sticky;
  top: 20px;
}
.map-sticky iframe {
  height: 600px;
}

footer {
  background-color: var(--bg-dark);
  padding: 10px 0;
  color: #dcdcdc;
}
.app-link {
  text-align: center;
  margin: 20px 0;
}
.app-link ul {
  padding: 0;
  margin: 0;
}
.app-link ul li {
  padding: 0;
  margin: 0 10px;
  list-style: none;
  display: inline-block;
}
.app-link ul li img {
  width: 130px;
}
.f-link {
  border: 1px solid #0c4d6d;
  border-width: 1px 0 1px 0;
  padding: 10px 0;
}
.f-link ul {
  padding: 0;
  margin: 0;
}
.f-link ul li {
  padding: 0;
  list-style: none;
  position: relative;
  margin: 0 10px;
}
.f-link ul li:before {
  content: "/";
  position: absolute;
  width: 10px;
  top: 8px;
  left: -11px;
}
.f-link ul li:first-child:before {
  content: "";
}
.f-link ul li a {
  color: #dcdcdc;
}
.f-link ul li a:hover {
  color: var(--primary-color);
}

.sm-link {
  padding: 20px 0 0 0;
}
.sm-link ul {
  padding: 0;
  margin: 0;
}
.sm-link ul li {
  padding: 0;
  list-style: none;
  position: relative;
  margin: 0 5px;
}
.sm-link ul li:first-child:before {
  content: "";
}
.sm-link ul li a {
  color: #000;
  background-color: #fff;
  display: inline-block;
  padding: 4px 8px;
  font-size: 20px;
  width: 36px;
  border-radius: 50px;
  height: 36px;
}
.sm-link ul li a:hover {
  background-color: var(--primary-color);
  color: #fff;
}

.f-logo {
  width: 130px;
}
.copyright-txt {
  text-align: center;
  margin: 20px 0 20px 0;
}
.copyright {
  border-top: 1px solid #0c4d6d;
  padding: 10px 0;
}

@media only screen and (max-width: 1367px) {
  html {
    font-size: 96%;
  }
  .st-logo {
    width: 85px;
  }
  .listing-status ul li span {
    margin-left: 24px;
  }
  .prop-details ul li img {
    height: 18px;
  }
}

@media only screen and (max-width: 1299px) {
  html {
    font-size: 96%;
  }
  .home {
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: 0 100%;
    padding: 0 0 70px 0;
    min-height: 552px;
  }
  .otp-field-1 {
    padding: 10px;
  }
  .otp-txt {
    text-align: right;
  }
  .listing-status {
    padding: 0 20px;
  }
  .listing-status h5 {
    border-radius: 12px;
    padding: 10px;
    margin: 0 0 20px 0;
  }
  .listing-status ul li span {
    margin: 10px 0 0 0;
  }
}

@media only screen and (max-width: 1023px) {
  html {
    font-size: 96%;
  }
  .home {
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: 0 100%;
    padding: 0 0 70px 0;
    min-height: 552px;
  }
  .logo img {
    width: 100px;
  }
  .menus ul li {
    margin: 0 -2px;
  }
  .menus ul li::before {
    left: -2px;
  }
  .signin-btn {
    width: 97px;
    padding: 5px 0;
  }
  .signin-btn span img {
    margin-right: 6px;
  }
  .home-mid h1 {
    margin-top: 100px;
    font-size: 1.6rem;
  }
  .otp-field-1 {
    padding: 7px;
  }
  .otp-txt {
    text-align: left;
  }
  .s-info-txt {
    text-align: left;
  }
  .menus ul li {
    margin: 0 7px;
  }
  .top-rgt ul li.app-icon img {
    width: 96px;
  }
  .listing-status h5 {
    border-radius: 12px;
    padding: 10px;
  }
  .listing-status ul li span {
    margin: 10px 0 0 0;
  }
}

@media only screen and (max-width: 475px) {
  html {
    font-size: 90%;
  }
  .home {
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: 0 100%;
    padding: 0 0 70px 0;
    min-height: auto;
  }
  header {
    padding-top: 20px;
    text-align: center;
  }
  .logo {
    width: 100%;
    text-align: center;
  }
  .logo img {
    width: 130px;
    text-align: center;
  }
  .menus {
    margin-top: 20px;
  }
  .menus ul li {
    margin: 0 -2px;
  }
  .menus ul li::before {
    left: -2px;
  }
  .top-rgt ul {
    text-align: center;
    margin-top: 20px;
  }
  .top-rgt ul li {
    padding: 0;
    margin: 0 5px;
    list-style: none;
    display: inline-block;
  }
  .top-rgt ul li.app-icon img {
    width: 100px;
  }
  .signin-btn {
    width: 100px;
    padding: 5px 0;
  }
  .signin-btn span img {
    margin-right: 6px;
  }
  .home-mid h1 {
    margin-top: 40px;
    font-size: 1.7rem;
  }
  .home-mid h1 span {
    display: block;
  }
  .home-mid p {
    font-size: 1.2rem;
    margin: 40px 0 30px 0;
  }
  .home-mid ul li {
    display: block;
    margin-top: 20px;
  }
  .inner-wrap {
    padding: 30px 10px;
  }
  .inner-wrap h1 {
    font-size: 2rem;
  }
  .otp-field-1 {
    padding: 7px;
  }
  .s-info-txt {
    text-align: left;
  }
  .otp-txt {
    text-align: left;
    margin-bottom: 10px;
  }

  .top-rgt2 {
    text-align: center;
    font-size: 1.2rem;
    margin-top: 15px;
  }
  .info-txt2 span {
    margin: 0;
  }

  .listing-status h5 {
    border-radius: 50px;
  }
  .listing-status {
    border: none;
    padding: 30px 0;
  }
  .f-link ul li {
    margin: 0 2px;
  }
  .f-link ul li::before {
    left: -5px;
  }
  .shot-by {
    text-align: left;
  }
  .map-sticky iframe {
    height: 400px;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 350px) {
  html {
    font-size: 80%;
  }
  .home-btn {
    width: 234px;
  }
  .app-link ul li img {
    width: 120px;
  }
  .app-link ul li {
    margin: 0 10px;
  }
  .menus ul li {
    margin: 0 -3px;
  }
  .menus ul li::before {
    left: -2px;
    top: 6px;
  }
}

/* utilities classes */
.pe-cursor {
  cursor: pointer;
}

button:disabled {
  pointer-events: auto !important;
  cursor: not-allowed !important;
}
